import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { DownloadAppSection } from '../components/download-app';

class IndexPage extends React.Component {

  render() {
    return (
      <Layout>
        <div id='main' className='container-fluid'>
          <SEO title="Home"/>
          <DownloadAppSection/>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
