import React from 'react';
import MyHealthBookIcon from '../images/vectors/healthbook-logo.svg';
import DoctorImage from '../images/vectors/image-doctor.svg';
import { Link } from 'gatsby';

export const ComingSoon = () => (
  <>
    <header className={`header container-fluid`} id='navbar'>
      {/*<div className={`row align-items-center`}>*/}
      {/*  <div className='col-md-4 header-title'>*/}
      {/*    <Link to='/'>*/}
      {/*      <span className="cursor-pointer">*/}
      {/*      <MyHealthBookIcon/>*/}
      {/*    </span>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className='logo-container'>
        <MyHealthBookIcon/>
      </div>
      <div className='doctor-image-container'>
        <DoctorImage/>
        <div className='coming-soon-title'>Coming Soon</div>
      </div>
    </header>
  </>
);
