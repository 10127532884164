import React from 'react';
import GooglePlayIcon from '../images/vectors/google-play.svg';

export const DownloadAppSection = () => (
  <section id='download-app'>
    <div className='download-title'>Download My HealthBook App Now</div>
    <a href="https://play.google.com/store/apps/details?id=in.medtrail.myhealthbook" target="_blank">
      <GooglePlayIcon/>
    </a>
  </section>
);
