import React from 'react';
import PropTypes from 'prop-types';
import {Footer} from '@medtrail/shared-ui';
import './layout.css';
import { WEBSITE } from '../constants';
import { ComingSoon } from './coming-soon';

const Layout = ({ children }) => {
  return (
    <>
      {/*<Header customStyle='non-sticky' active='active' routeFlag={ROUTE_FLAG.homePage}/>*/}
      {/*<Header customStyle='sticky' active='active' routeFlag={ROUTE_FLAG.homePage}/>*/}
      <ComingSoon/>
      <main>{children}</main>
      <Footer website={WEBSITE} className="health-book"/>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
